import React from 'react';
import styles from './CallToAction.module.css';
import { Link } from 'react-router-dom';
import { CONTACT } from '../../Constants/Routes';
import { motion, cubicBezier } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const CallToAction = ({ title, description, isInView }) => {
  const [t] = useTranslation('global');
  const variants = {
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: cubicBezier(0.17, 0.55, 0.55, 1),
        staggerChildren: 0.1,
      },
    },
    bottom: { opacity: 0, y: '200px' },
  };

  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center text-center px-4 px-lg-2 mx-auto ${styles.container}`}
    >
      <motion.div
        initial='bottom'
        animate={isInView && 'animate'}
        variants={variants}
      >
        <motion.h2 className='display-4 fw-bold mb-4' variants={variants}>
          {title}
        </motion.h2>
        <motion.div
          className={`${styles['container--medium']} mx-auto`}
          variants={variants}
        >
          <p className='fs-5 mb-5'>{description}</p>
        </motion.div>
        <motion.div variants={variants}>
          <Link
            to={CONTACT}
            className={`btn rounded-pill btn-lg btn-light shadow ${styles.button}`}
          >
            {t('callToAction.btn')}
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default CallToAction;
