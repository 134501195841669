import React, { useEffect, useRef, useState } from 'react';
import styles from './PSI.module.css';
import coverImage from '../../img/cover2.jpg';
import Partners from '../../components/Partners/Partners';
import Transition from '../../components/Transition/Transition';
import PortfolioCard from '../../components/Card/PortfolioCard';
import { BsCashCoin, BsFillAwardFill, BsFillPeopleFill } from 'react-icons/bs';
import CallToAction from '../../components/CTA/CallToAction';
import { motion, cubicBezier, useInView } from 'framer-motion';

const PSI = () => {
  const [isMobile, setIsMobile] = useState(false);
  let animationOffset = isMobile ? '0px' : '-400px';

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const variants = {
    left: {
      x: -350,
      opacity: 0,
    },
    right: {
      x: 350,
      opacity: 0,
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: cubicBezier(0.17, 0.55, 0.55, 1),
        staggerChildren: 0.1,
      },
    },
    bottom: { opacity: 0, y: '500px' },
    scale: { opacity: 0, scale: 0.3 },
    scaleActive: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: cubicBezier(0.17, 0.55, 0.55, 1),
      },
    },
  };

  const aboutSectionRef = useRef();
  const isAboutSectionInView = useInView(aboutSectionRef, {
    margin: animationOffset,
  });

  const serviceSectionRef = useRef();
  const isServiceSectionInView = useInView(serviceSectionRef, {
    margin: animationOffset,
  });

  const clientsSectionRef = useRef();
  const isClientsSectionInView = useInView(clientsSectionRef, {
    margin: animationOffset,
  });

  const ctaSectionRef = useRef();
  const isCtaSectionInView = useInView(ctaSectionRef, {
    margin: animationOffset,
  });

  const portfolioSectionRef = useRef();
  const isPortfolioSectionInView = useInView(portfolioSectionRef, {
    margin: animationOffset,
  });

  return (
    <div className={styles.main}>
      {/* Hero section */}
      <section className={styles.section}>
        <h1 className='display-3'>PSI</h1>
      </section>

      {/* About Section */}
      <section
        className={`row px-4 mx-auto ${styles.container} ${styles['container-about']}`}
        ref={aboutSectionRef}
      >
        <motion.div
          className='col-lg-6 d-flex flex-column justify-content-center align-items-start mb-4 mb-lg-0 text-center text-lg-start'
          initial='left'
          animate={isAboutSectionInView && 'animate'}
          variants={variants}
        >
          <motion.p className='fs-5 mb-1 mx-auto mx-lg-0' variants={variants}>
            About Us
          </motion.p>
          <motion.h2
            className='display-5 mb-4 mx-auto mx-lg-0'
            variants={variants}
          >
            We Help Companies To Get Success
          </motion.h2>
          <motion.p className='lead' variants={variants}>
            Our company employs tailored strategies and personalized solutions
            to empower clients, ensuring they reach their goals with precision
            and efficiency. Through collaborative partnerships and innovative
            methodologies, we guide our clients towards success by leveraging
            our expertise to overcome obstacles and seize opportunities in their
            respective industries.
          </motion.p>
        </motion.div>
        <motion.div
          className='col-lg-5 offset-lg-1 d-flex justify-content-center justify-content-lg-end align-items-center'
          initial='scale'
          animate={isAboutSectionInView && 'scaleActive'}
          variants={variants}
        >
          <img
            className={`rounded-3 ${styles.image}`}
            src='https://placehold.co/500x400'
            alt=''
          />
        </motion.div>
      </section>

      {/* Services Section */}
      <section
        className={`mx-auto px-4 ${styles.container} ${styles['container-services']}`}
        ref={serviceSectionRef}
      >
        <motion.div
          initial='left'
          animate={isServiceSectionInView && 'animate'}
          variants={variants}
        >
          <motion.p className='fs-5 text-center mb-0' variants={variants}>
            What We Do
          </motion.p>
          <motion.h2 className='display-5 text-center mb-5' variants={variants}>
            Our Best Services For You
          </motion.h2>
        </motion.div>
        <motion.div
          className='row row-cols-1 row-cols-lg-3 my-0 my-sm-3'
          initial='bottom'
          animate={isServiceSectionInView && 'animate'}
          variants={variants}
        >
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title='Finance'
                description='Our company latest real estate project introduces a modern, eco-friendly residential complex designed to seamlessly blend luxury living with sustainable practices'
                iconLink={<BsCashCoin />}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title='Consulting'
                description='Our company latest real estate project introduces a modern, eco-friendly residential complex designed to seamlessly blend luxury living with sustainable practices'
                iconLink={<BsFillPeopleFill />}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4'>
            <div className='d-flex justify-content-center' variants={variants}>
              <PortfolioCard
                title='Licensing'
                description='Our company latest real estate project introduces a modern, eco-friendly residential complex designed to seamlessly blend luxury living with sustainable practices'
                iconLink={<BsFillAwardFill />}
              />
            </div>
          </motion.div>
        </motion.div>
      </section>

      {/* Clients Section */}
      <section className='margin-bottom-lg' ref={clientsSectionRef}>
        <Partners isInView={isClientsSectionInView} />
      </section>

      {/* Portfolio Section */}
      <section
        className={`mx-auto px-4 ${styles.container} ${styles['container-projects']}`}
        ref={portfolioSectionRef}
      >
        <motion.div
          initial='left'
          animate={isPortfolioSectionInView && 'animate'}
          variants={variants}
        >
          <p className='fs-5 text-center mb-0'>Projects</p>
          <h2 className='display-5 text-center'>Our Recent Works</h2>
          <p className='fs-6 text-center margin-bottom-md'>
            Groundbreaking projects led by our team, redefining industry
            standards globally
          </p>
        </motion.div>

        <motion.div
          className='row row-cols-1 row-cols-lg-3 my-0 my-sm-3'
          initial='bottom'
          animate={isPortfolioSectionInView && 'animate'}
        >
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title='Mawatu'
                description='Our company latest real estate project introduces a modern, eco-friendly residential complex designed to seamlessly blend luxury living with sustainable practices'
                imageLink={coverImage}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title='IKN'
                description='Our company latest real estate project introduces a modern, eco-friendly residential complex designed to seamlessly blend luxury living with sustainable practices'
                imageLink={coverImage}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title='BSD'
                description='Our company latest real estate project introduces a modern, eco-friendly residential complex designed to seamlessly blend luxury living with sustainable practices'
                imageLink={coverImage}
              />
            </div>
          </motion.div>
        </motion.div>
      </section>

      {/* Call To Action Section*/}
      <section className='container-fluid px-0 px-sm-3' ref={ctaSectionRef}>
        <CallToAction
          title='Ready to change your business route?'
          description="Either it's for your next big project or enhancing daily operations, our service delivers excellence. Seize the opportunity now and propel your business forward!"
          isInView={isCtaSectionInView}
        />
      </section>
    </div>
  );
};

export default Transition(PSI);
