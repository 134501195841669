import React, { useEffect, useRef, useState } from 'react';
import styles from './Navbar.module.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import * as ROUTES from '../../Constants/Routes';
import HamburgerMenu from './components/HamburgerMenu';
import { useTranslation } from 'react-i18next';
import { HiLanguage } from 'react-icons/hi2';

const Navbar = () => {
  const LARGE_SCREEN_SIZE = 992;
  const ALL_ROUTES = Object.values(ROUTES);
  const [isScrolled, setIsScrolled] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);
  const [isTogglerActive, setIsTogglerActive] = useState(false);
  const { pathname } = useLocation();
  const navbar = useRef(null);
  const windowWidth = useRef(window.innerWidth);
  const [t, i18n] = useTranslation('global');
  const [isEnglish, setIsEnglish] = useState(true);

  useEffect(() => {
    setTopOffset(navbar.current.offsetTop);
  }, []);

  window.onscroll = () => {
    if (window.scrollY >= topOffset && isScrolled === false) {
      setIsScrolled(true);
    } else if (window.scrollY <= topOffset && isScrolled === true) {
      setIsScrolled(false);
    }
  };

  const isNotFoundPage = () => {
    return !ALL_ROUTES.includes(pathname) || pathname === ROUTES.CONTACT;
  };

  const isScrolledOrMobile = () => {
    return isScrolled || windowWidth.current < LARGE_SCREEN_SIZE;
  };

  const handleClick = () => {
    setTimeout(() => {
      setIsTogglerActive(false);
      document.body.classList.remove('overflow-hidden');
    }, 400);
  };

  const handleToggler = () => {
    console.log('handleToggler');
    if (window.innerWidth < 992) {
      setIsTogglerActive(!isTogglerActive);
      setIsAnimationRunning(true);
      if (!isTogglerActive) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
      setTimeout(() => {
        setIsAnimationRunning(false);
      }, 500);
    }
  };

  const handleChangeLanguage = () => {
    if (!isEnglish) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('id');
    }
    setIsEnglish(!isEnglish);
  };

  return (
    <nav
      className={`${styles.navbar} ${
        isScrolledOrMobile() && styles['navbar-scrolled']
      } ${isNotFoundPage() && styles['navbar-dark']}`}
      ref={navbar}
    >
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Link to={ROUTES.HOME}>
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg'
              className={styles.navbar__logo}
              alt='Logo'
            />
          </Link>
        </div>
        <div className={`${styles.container} ${styles['container--desktop']}`}>
          <ul className={styles.nav__links}>
            <li>
              <NavLink
                className={styles['navbar-link']}
                aria-current='page'
                to={ROUTES.HOME}
              >
                <span className={styles['nav-text']}>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink className={styles['navbar-link']} to={ROUTES.PSI}>
                <span className={styles['nav-text']}>PSI</span>
              </NavLink>
            </li>
            <li>
              <NavLink className={styles['navbar-link']} to={ROUTES.PDI}>
                <span className={styles['nav-text']}>PDI x MWM</span>
              </NavLink>
            </li>
            <li>
              <NavLink className={styles['navbar-link']} to={ROUTES.CONTACT}>
                <span className={styles['nav-text']}>Contact Us</span>
              </NavLink>
            </li>
            <li>
              <button
                className={`btn ${styles['btn-language']}`}
                onClick={() => handleChangeLanguage()}
              >
                <HiLanguage className={styles['icon']} />{' '}
                {isEnglish ? 'EN' : 'ID'}
              </button>
            </li>
          </ul>
        </div>
        <button
          className={styles.navbar__button}
          disabled={isAnimationRunning}
          onClick={() => handleToggler()}
        >
          <HamburgerMenu isTogglerActive={isTogglerActive} />
        </button>
      </div>

      <div
        className={`${styles['container--mobile']} ${
          isTogglerActive
            ? styles['container--active']
            : styles['container--inactive']
        }`}
      >
        <ul className={styles['nav__links-mobile']}>
          <li>
            <NavLink
              className={styles['navbar-link']}
              aria-current='page'
              to={ROUTES.HOME}
              onClick={handleClick}
            >
              <span className={styles['nav-text']}>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles['navbar-link']}
              to={ROUTES.PSI}
              onClick={handleClick}
            >
              <span className={styles['nav-text']}>PSI</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles['navbar-link']}
              to={ROUTES.PDI}
              onClick={handleClick}
            >
              <span className={styles['nav-text']}>PDI x MWM</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles['navbar-link']}
              to={ROUTES.CONTACT}
              onClick={handleClick}
            >
              <span className={styles['nav-text']}>Contact Us</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
