import React, { useRef, useState } from 'react';
import styles from './Contact.module.css';
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaMapMarker,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
} from 'react-icons/fa';
import swal from 'sweetalert';
import Transition from '../../components/Transition/Transition';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const form = useRef();
  const [t] = useTranslation('global');

  const isEmailValidated = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const showSuccessAlert = () => {
    swal({
      title: 'Thank you',
      text: 'Message Sent Successfully!',
      icon: 'success',
      button: 'Close',
    });
  };

  const showErrorAlert = (message) => {
    swal({
      title: 'Oops...',
      text: message,
      icon: 'error',
      button: 'Close',
    });
  };

  const resetForm = () => {
    setFullName('');
    setEmailAddress('');
    setPhoneNumber('');
    setMessage('');
  };

  const isPhoneNumberValid = (phoneNumber) => {
    return /^\d+$/.test(phoneNumber);
  };

  const handleSubmit = () => {
    let isFullNameError = false;
    let isEmailAddressError = false;
    let isEmailNotValid = false;
    let isPhoneNumberError = false;
    let isPhoneNumberNotValid = false;
    let isMessageError = false;

    if (fullName.length < 5 || fullName.length > 30) {
      isFullNameError = true;
    }
    if (emailAddress.length < 5) {
      isEmailAddressError = true;
    }
    if (!isEmailValidated(emailAddress) || emailAddress.length > 50) {
      isEmailNotValid = true;
    }
    if (phoneNumber.length < 9 || phoneNumber.length > 15) {
      isPhoneNumberError = true;
    }
    if (!isPhoneNumberValid(phoneNumber)) {
      isPhoneNumberNotValid = true;
    }
    if (message.length < 5 || message.length > 1000) {
      isMessageError = true;
    }

    if (
      !isFullNameError &&
      !isEmailAddressError &&
      !isEmailNotValid &&
      !isPhoneNumberError &&
      !isPhoneNumberNotValid &&
      !isMessageError
    ) {
      sendEmail();
    } else if (isFullNameError) {
      showErrorAlert('Please enter your full name.');
    } else if (isEmailAddressError) {
      showErrorAlert('Please enter your email address.');
    } else if (isEmailNotValid) {
      showErrorAlert('Please enter a valid email address.');
    } else if (isPhoneNumberError) {
      showErrorAlert('Please enter your phone number.');
    } else if (isPhoneNumberNotValid) {
      showErrorAlert('Please enter a valid phone number.');
    } else {
      showErrorAlert('Please enter a message.');
    }
  };

  const sendEmail = () => {
    emailjs
      .sendForm(
        'service_wzw6gz8',
        'template_jtvwsxn',
        form.current,
        'user_d5nSfN3SCVgW7Odws1b4v'
      )
      .then(
        (result) => {
          showSuccessAlert();
          resetForm();
        },
        (error) => {
          showErrorAlert(
            'An error occured while sending your message. Please try again later.'
          );
        }
      );
  };

  return (
    <main className={styles.main}>
      {/* Contact Form */}
      <div className={`container ${styles.container}`}>
        <br />
        <br />
        <br />
        <div className={styles['contact-form']}>
          <div className={styles['contact-form-box__left']}>
            <h3 className={styles['m-b-15']}>{t('contact.contact.cta')}</h3>
            <hr />
            <div>
              <small className={styles['contact__text']}>
                <FaUser className={styles['m-r-5']} />
                {t('contact.contact.name')}
              </small>
              <p>Syiwa Widjanarko</p>
            </div>
            <div>
              <small className={styles['contact__text']}>
                <FaEnvelope className={styles['m-r-5']} />{' '}
                {t('contact.contact.email')}
              </small>
              <p>name@gmail.com</p>
            </div>
            <div>
              <small className={styles['contact__text']}>
                <FaPhone className={styles['m-r-5']} />{' '}
                {t('contact.contact.phone')}
              </small>
              <p>+62 1234567890</p>
            </div>
            <div>
              <small className={styles['contact__text']}>
                <FaMapMarker className={styles['m-r-5']} />
                {t('contact.contact.address')}
              </small>
              <p>
                Treepark Apartment & Commercial Serpong, Ruko CG-01 Sunburst CBD
                LOT II/17, BSD City Tangerang Selatan{' '}
              </p>
            </div>
            <div className={styles['contact-form-box__left_footer']}>
              <a href='#!'>
                <FaFacebookF className={styles['contact__icon']} />
              </a>{' '}
              <a href='#!'>
                <FaTwitter className={styles['contact__icon']} />
              </a>{' '}
              <a href='#!'>
                <FaInstagram className={styles['contact__icon']} />
              </a>{' '}
            </div>
          </div>
          <div className={styles['contact-form-box__right']}>
            <h3 className={styles['m-b-15']}>{t('contact.contact.title')}</h3>
            <hr />
            <form ref={form} onSubmit={handleSubmit}>
              <div className='lab-grid mb-4'>
                <div className='lab-grid-cell lab-grid-cell--mb-2x w-third--d w-half--t w-full--m'>
                  <div className={styles['floating-label-wrap']}>
                    <input
                      name='name'
                      type='text'
                      className={`${styles['floating-label-field']} ${styles['floating-label-field--s1']}`}
                      id='field-1'
                      placeholder='Full Name'
                      minLength={5}
                      maxLength={30}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <label
                      htmlFor='field-1'
                      className={styles['floating-label']}
                    >
                      {t('contact.contact.formNameLabel')}
                    </label>
                  </div>
                </div>

                <div className='lab-grid-cell lab-grid-cell--mb-2x w-third--d w-half--t w-full--m'>
                  <div className={styles['floating-label-wrap']}>
                    <input
                      name='email'
                      type='email'
                      className={`${styles['floating-label-field']} ${styles['floating-label-field--s1']}`}
                      id='field-2'
                      placeholder='Email Address'
                      maxLength={40}
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                    <label
                      htmlFor='field-2'
                      className={styles['floating-label']}
                    >
                      {t('contact.contact.formEmailLabel')}
                    </label>
                  </div>
                </div>

                <div className='lab-grid-cell lab-grid-cell--mb-2x w-third--d w-half--t w-full--m'>
                  <div className={styles['floating-label-wrap']}>
                    <input
                      name='phone'
                      type='tel'
                      className={`${styles['floating-label-field']} ${styles['floating-label-field--s1']}`}
                      id='field-4'
                      placeholder='Phone Number'
                      value={phoneNumber}
                      minLength={9}
                      maxLength={15}
                      pattern='[0-9]+'
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <label
                      htmlFor='field-4'
                      className={styles['floating-label']}
                    >
                      {t('contact.contact.formPhoneLabel')}
                    </label>
                  </div>
                </div>

                <div className='lab-grid-cell lab-grid-cell--mb-2x w-full'>
                  <div className={styles['floating-label-wrap']}>
                    <textarea
                      name='msg'
                      className={`${styles['floating-label-field']} ${styles['floating-label-field--s1']}`}
                      id='field-7'
                      placeholder='Message'
                      rows='8'
                      data-gramm='false'
                      data-gramm_editor='false'
                      data-enable-grammarly='false'
                      minLength={5}
                      maxLength={1000}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    <label
                      htmlFor='field-7'
                      className={styles['floating-label']}
                    >
                      {t('contact.contact.formMessageLabel')}
                    </label>
                  </div>
                </div>
              </div>

              <div className='d-grid'>
                <button
                  type='button'
                  className={`btn ${styles['btn-primary']} btn-lg`}
                  onClick={() => handleSubmit()}
                >
                  {t('contact.contact.submit')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Maps */}
      <div className='container text-center mb-5'>
        <div className='row'>
          <div className={`col-lg-6 ${styles.map} mb-5`}>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1043.1928153098147!2d106.6638746859801!3d-6.298805694546845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb2e0fed6525%3A0xe0c3ddfa59540582!2sTreepark%20Serpong!5e0!3m2!1sid!2sid!4v1702887597403!5m2!1sid!2sid'
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
          <div className='col-lg-6 d-flex flex-column justify-content-center align-items-center'>
            <h2 className='display-6 fw-bold mb-3'>
              {t('contact.office.title')}
            </h2>
            <p className={`fs-6 ${styles.text}`}>
              {t('contact.office.description')}
            </p>
            <p className='fs-5 mb-1'>{t('contact.office.workHourLabel')}:</p>
            <p className='fs-6 mb-1'>
              {t('contact.office.workHourDescription')} 09:00 - 18:00
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Transition(Contact);
