import React, { useEffect, useRef } from 'react';
import styles from './Home.module.css';
import Transition from '../../components/Transition/Transition';
import PortfolioCard from '../../components/Card/PortfolioCard';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleLeft,
  faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  COMPANY_MISSION,
  COMPANY_VALUES,
  COMPANY_VISION,
  MOBILE_RESOLUTION,
} from '../../Constants';
import CallToAction from '../../components/CTA/CallToAction';
import { cubicBezier, motion, useInView } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import teamMeetingAbout from '../../img/home/team-meeting-1.jpg';
import teamMeetingVision from '../../img/home/team-meeting-2.jpg';
import testimonialOne from '../../img/home/testimonial-1.jpg';
import testimonialTwo from '../../img/home/testimonial-2.jpg';
import testimonialThree from '../../img/home/testimonial-3.jpg';

const Home = () => {
  const [t] = useTranslation('global');
  const [isFirstCountUpTriggered, setIsFirstCountUpTrigerred] = useState(true);
  const [isSecondCountUpTriggered, setIsSecondCountUpTrigerred] =
    useState(true);
  const [isThirdCountUpTriggered, setIsThirdCountUpTrigerred] = useState(true);
  const [isFourthCountUpTriggered, setIsFourthCountUpTrigerred] =
    useState(true);
  const [companyValues, setCompanyValues] = useState(COMPANY_MISSION);
  const [isMobile, setIsMobile] = useState(false);
  let visibilityOffset = isMobile ? 10 : 50;
  let animationOffset = isMobile ? '0px' : '-400px';

  useEffect(() => {
    if (window.innerWidth < MOBILE_RESOLUTION) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const handleFirstVisibility = (isVisible) => {
    if (isVisible) {
      setIsFirstCountUpTrigerred(false);
    }
  };
  const handleSecondVisibility = (isVisible) => {
    if (isVisible) {
      setIsSecondCountUpTrigerred(false);
    }
  };
  const handleThirdVisibility = (isVisible) => {
    if (isVisible) {
      setIsThirdCountUpTrigerred(false);
    }
  };
  const handleFourthVisibility = (isVisible) => {
    if (isVisible) {
      setIsFourthCountUpTrigerred(false);
    }
  };

  const variants = {
    left: {
      x: -350,
      opacity: 0,
    },
    right: {
      x: 350,
      opacity: 0,
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: cubicBezier(0.17, 0.55, 0.55, 1),
        staggerChildren: 0.1,
      },
    },
    bottom: { opacity: 0, y: '500px' },
    scale: { opacity: 0, scale: 0.5 },
    scaleActive: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: cubicBezier(0.17, 0.55, 0.55, 1),
      },
    },
  };

  const heroSectionRef = useRef();
  const isHeroSectionInView = useInView(heroSectionRef);

  const aboutSectionRef = useRef();
  const isAboutSectionInView = useInView(aboutSectionRef, {
    margin: animationOffset,
  });

  const visionSectionRef = useRef();
  const isVisionSectionInView = useInView(visionSectionRef, {
    margin: animationOffset,
  });

  const portfolioSectionRef = useRef();
  const isPortfolioSectionInView = useInView(portfolioSectionRef, {
    margin: animationOffset,
  });

  const testimonialSectionRef = useRef();
  const isTestimonialSectionInView = useInView(testimonialSectionRef, {
    margin: animationOffset,
  });

  const ctaSectionRef = useRef();
  const isCtaSectionInView = useInView(ctaSectionRef, {
    margin: animationOffset,
  });

  return (
    <div className={styles.main}>
      {/* Hero Section */}
      <section
        className={`${styles.section} ${styles.hero}`}
        ref={heroSectionRef}
      >
        <motion.div
          className='text-center'
          initial='bottom'
          animate={isHeroSectionInView && 'animate'}
          variants={variants}
        >
          <motion.h1 className='display-1' variants={variants}>
            Prabawa Group
          </motion.h1>
          <motion.p className='lead' variants={variants}>
            It's more than just a company
          </motion.p>
        </motion.div>
      </section>

      {/* About Us */}
      <section
        className={`row px-4 mx-auto ${styles.container} ${styles['container-about']}`}
        ref={aboutSectionRef}
      >
        <motion.div
          className='col-lg-6 d-flex flex-column justify-content-center align-items-start mb-4 mb-lg-0 text-center text-lg-start'
          initial='left'
          animate={isAboutSectionInView && 'animate'}
          variants={variants}
        >
          <motion.p className='fs-5 mb-1 mx-auto mx-lg-0' variants={variants}>
            {t('home.about.label')}
          </motion.p>
          <motion.h2
            className='display-5 mb-4 mx-auto mx-lg-0'
            variants={variants}
          >
            {t('home.about.title')}
          </motion.h2>
          <motion.p className='lead' variants={variants}>
            {t('home.about.description')}
          </motion.p>
        </motion.div>
        <motion.div
          className='col-lg-5 offset-lg-1 d-flex justify-content-center justify-content-lg-end align-items-center'
          initial='scale'
          animate={isAboutSectionInView && 'scaleActive'}
          variants={variants}
        >
          <img
            className={`rounded-3 ${styles.image}`}
            src={teamMeetingAbout}
            alt=''
          />
        </motion.div>
      </section>

      {/* Vision & Mission */}
      <section
        className={`container-fluid px-4 mx-auto ${styles.container} ${styles['container-values']} `}
        ref={visionSectionRef}
      >
        <div className='row flex-column-reverse justify-content-center align-items-center flex-lg-row'>
          <motion.div
            className='col-lg-5 d-flex justify-content-center align-items-center'
            initial='scale'
            animate={isVisionSectionInView && 'scaleActive'}
            variants={variants}
          >
            <img
              className={`rounded-3 ${styles.image}`}
              src={teamMeetingVision}
              alt=''
            />
          </motion.div>
          <motion.div
            className={`offset-lg-1 col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0 text-center text-lg-start ${styles['container--small']}`}
            initial='right'
            animate={isVisionSectionInView && 'animate'}
            variants={variants}
          >
            <motion.h2 className='display-5 mb-4' variants={variants}>
              {t('home.vision.title')}
            </motion.h2>
            <motion.p className='fs-6 mb-4' variants={variants}>
              {t('home.vision.description')}
            </motion.p>
            <motion.div
              className='d-flex gap-2 gap-lg-5 mb-2 mx-auto mx-lg-0'
              variants={variants}
            >
              <motion.button
                className={`px-4 py-2 fs-6 ${styles['nav-link']} ${
                  companyValues === COMPANY_MISSION && styles.active
                }`}
                onClick={() => setCompanyValues(COMPANY_MISSION)}
              >
                {t('home.vision.missionLabel')}
              </motion.button>
              <motion.button
                className={`px-4 py-2 fs-6 ${styles['nav-link']} ${
                  companyValues === COMPANY_VISION && styles.active
                }`}
                onClick={() => setCompanyValues(COMPANY_VISION)}
              >
                {t('home.vision.visionLabel')}
              </motion.button>
              <motion.button
                className={`px-4 py-2 fs-6 ${styles['nav-link']} ${
                  companyValues === COMPANY_VALUES && styles.active
                }`}
                onClick={() => setCompanyValues(COMPANY_VALUES)}
              >
                {t('home.vision.valuesLabel')}
              </motion.button>
            </motion.div>
            <motion.div
              className={`${styles['container-text']} mt-4 p-3 mx-auto mx-lg-0`}
              variants={variants}
            >
              {companyValues === COMPANY_MISSION && (
                <motion.p className='fs-6 mb-0'>
                  {t('home.vision.missionDescription')}
                </motion.p>
              )}
              {companyValues === COMPANY_VISION && (
                <motion.p className='fs-6 mb-0'>
                  {t('home.vision.visionDescription')}
                </motion.p>
              )}
              {companyValues === COMPANY_VALUES && (
                <motion.p className='fs-6 mb-0'>
                  {t('home.vision.valuesDescription')}
                </motion.p>
              )}
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Stats */}
      <section
        id='stats'
        className={`${styles['container-stats']} container-fluid ${styles['container--darker']} text-white`}
      >
        <div className={`row my-lg-5 ${styles.wrapper}`}>
          <div className='col-md-3 col-sm-6 mb-2 mb-md-0 text-center border-lg-end border-light'>
            <h2 className='display-2'>
              <VisibilitySensor
                partialVisibility
                offset={{ bottom: visibilityOffset }}
                onChange={handleFirstVisibility}
                active={isFirstCountUpTriggered}
              >
                {({ isVisible }) => (
                  <div>
                    {isVisible ? (
                      <CountUp
                        start={0}
                        end={400}
                        duration={2}
                        suffix='+'
                        scrollSpyOnce={true}
                      />
                    ) : (
                      0
                    )}
                  </div>
                )}
              </VisibilitySensor>
            </h2>
            <h3 className='h4'>{t('home.achievements.clientLabel')}</h3>
          </div>
          <div className='col-md-3 col-sm-6 mb-2 mb-md-0 text-center border-lg-end border-light'>
            <h2 className='display-2'>
              <VisibilitySensor
                partialVisibility
                offset={{ bottom: visibilityOffset }}
                onChange={handleSecondVisibility}
                active={isSecondCountUpTriggered}
              >
                {({ isVisible }) => (
                  <div>
                    {isVisible ? (
                      <CountUp
                        start={0}
                        end={700}
                        duration={2}
                        suffix='+'
                        scrollSpyOnce={true}
                      />
                    ) : (
                      0
                    )}
                  </div>
                )}
              </VisibilitySensor>
            </h2>
            <h3 className='h4'>{t('home.achievements.issuesLabel')}</h3>
          </div>
          <div className='col-md-3 col-sm-6 mb-2 mb-sm-0 text-center border-lg-end border-light'>
            <h2 className='display-2'>
              <VisibilitySensor
                partialVisibility
                offset={{ bottom: visibilityOffset }}
                onChange={handleThirdVisibility}
                active={isThirdCountUpTriggered}
              >
                {({ isVisible }) => (
                  <div>
                    {isVisible ? (
                      <CountUp
                        start={0}
                        end={99}
                        duration={2.5}
                        suffix='%'
                        scrollSpyOnce={true}
                      />
                    ) : (
                      0
                    )}
                  </div>
                )}
              </VisibilitySensor>
            </h2>
            <h3 className='h4'>{t('home.achievements.satisfactionLabel')}</h3>
            <p></p>
          </div>
          <div className='col-md-3 col-sm-6 text-center'>
            <h2 className='display-2'>
              <VisibilitySensor
                partialVisibility
                offset={{ bottom: visibilityOffset }}
                onChange={handleFourthVisibility}
                active={isFourthCountUpTriggered}
              >
                {({ isVisible }) => (
                  <div>
                    {isVisible ? (
                      <CountUp
                        start={0}
                        end={50}
                        duration={2.5}
                        suffix='+'
                        scrollSpyOnce={true}
                      />
                    ) : (
                      0
                    )}
                  </div>
                )}
              </VisibilitySensor>
            </h2>
            <h3 className='h4'>{t('home.achievements.partnersLabel')}</h3>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section
        className={`mx-auto px-4 ${styles.container} ${styles['container-projects']}`}
      >
        <motion.div
          initial='left'
          animate={isPortfolioSectionInView && 'animate'}
          variants={variants}
          ref={portfolioSectionRef}
        >
          <motion.p className='fs-5 text-center mb-0' variants={variants}>
            {t('home.portfolio.label')}
          </motion.p>
          <motion.h2 className='display-5 text-center' variants={variants}>
            {t('home.portfolio.title')}
          </motion.h2>
          <motion.p
            className='fs-6 text-center margin-bottom-md'
            variants={variants}
          >
            {t('home.portfolio.description')}
          </motion.p>
        </motion.div>

        <motion.div
          className='row row-cols-1 row-cols-lg-3 my-0 my-sm-3'
          initial='bottom'
          animate={isPortfolioSectionInView && 'animate'}
          variants={variants}
        >
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('home.portfolio.projects.0.title')}
                description={t('home.portfolio.projects.0.description')}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('home.portfolio.projects.1.title')}
                description={t('home.portfolio.projects.1.description')}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('home.portfolio.projects.2.title')}
                description={t('home.portfolio.projects.2.description')}
              />
            </div>
          </motion.div>
        </motion.div>
      </section>

      {/* <!-- Testimonials --> */}
      <motion.section
        id='testimonials'
        initial='right'
        animate={isTestimonialSectionInView && 'animate'}
        variants={variants}
        className={`${styles.container} ${styles['container-testimonials']} px-4 mx-auto`}
        ref={testimonialSectionRef}
      >
        <motion.p className='fs-5 text-center mb-1' variants={variants}>
          {t('home.testimonial.label')}
        </motion.p>
        <motion.h2
          className='display-5 text-center margin-bottom-md'
          variants={variants}
        >
          {t('home.testimonial.title')}
        </motion.h2>
        <motion.div
          id='testimonialCarousel'
          className='carousel slide'
          variants={variants}
          data-bs-ride='carousel'
          data-bs-interval='8000'
          data-bs-touch='true'
        >
          <div className='carousel-inner'>
            {/* <!-- Slide 1 --> */}
            <div className='carousel-item active'>
              <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                <img
                  src={testimonialOne}
                  alt=''
                  className='rounded-circle'
                  width='120'
                />
                <p
                  className={`${
                    isMobile ? 'w-100' : 'w-50'
                  }  my-4 fst-italic fs-4 mb-4`}
                >
                  {t('home.testimonial.testimonials.0.description')}
                </p>
                <div className='fw-bold fs-5 mt-4'>
                  {t('home.testimonial.testimonials.0.name')}
                </div>
                <div>
                  {t('home.testimonial.testimonials.0.position')} -{' '}
                  {t('home.testimonial.testimonials.0.company')}
                </div>
              </div>
            </div>

            {/* <!-- Slide 2 --> */}
            <div className='carousel-item'>
              <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                <img
                  src={testimonialTwo}
                  alt=''
                  className='rounded-circle'
                  width='120'
                />
                <p
                  className={`${
                    isMobile ? 'w-100' : 'w-50'
                  } my-4 fst-italic fs-4 mb-4`}
                >
                  {t('home.testimonial.testimonials.1.description')}
                </p>
                <div className='fw-bold fs-5 mt-4'>
                  {t('home.testimonial.testimonials.1.name')}
                </div>
                <div>
                  {t('home.testimonial.testimonials.1.position')} -{' '}
                  {t('home.testimonial.testimonials.1.company')}
                </div>
              </div>
            </div>

            {/* <!-- Slide 3 --> */}
            <div className='carousel-item'>
              <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                <img
                  src={testimonialThree}
                  alt=''
                  className='rounded-circle'
                  width='120'
                />
                <p
                  className={`${
                    isMobile ? 'w-100' : 'w-50'
                  }  my-4 fst-italic fs-4 mb-4`}
                >
                  {t('home.testimonial.testimonials.2.description')}
                </p>
                <div className='fw-bold fs-5 mt-4'>
                  {t('home.testimonial.testimonials.2.name')}
                </div>
                <div>
                  {t('home.testimonial.testimonials.2.position')} -{' '}
                  {t('home.testimonial.testimonials.2.company')}
                </div>
              </div>
            </div>
          </div>
          <div className='d-none d-lg-block'>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#testimonialCarousel'
              data-bs-slide='prev'
            >
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                size='3x'
                className='text-secondary'
              />
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#testimonialCarousel'
              data-bs-slide='next'
            >
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                size='3x'
                className='text-secondary'
              />
            </button>
          </div>
        </motion.div>
      </motion.section>

      {/* Call To Action Section*/}
      <section className='container-fluid px-0 px-sm-3' ref={ctaSectionRef}>
        <CallToAction
          title={t('home.callToAction.title')}
          description={t('home.callToAction.description')}
          isInView={isCtaSectionInView}
        />
      </section>
    </div>
  );
};

export default Transition(Home);
