import React from 'react';
import { Link } from 'react-router-dom';
import { HOME } from '../../Constants/Routes';
import styles from './NotFound.module.css';

const NotFound = () => {
  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1>404</h1>
        </div>
        <h2>Page not found!</h2>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <Link to={HOME}>Back To Homepage</Link>
      </div>
    </main>
  );
};

export default NotFound;
