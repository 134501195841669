import React, { useState } from 'react';
import styles from './HamburgerMenu.module.css';

const HamburgerMenu = ({ isTogglerActive }) => {
  const [isAnimationRemoved, setIsAnimationRemoved] = useState(false);

  const handleClick = () => {
    if (!isAnimationRemoved) {
      setIsAnimationRemoved(true);
    }
  };
  return (
    <div
      className={`${styles.container} ${isTogglerActive ? styles.active : ''} ${
        !isAnimationRemoved ? styles['no-animation'] : ''
      }`}
      onClick={() => handleClick()}
    >
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
    </div>
  );
};

export default HamburgerMenu;
