import React from 'react';
import styles from './PortfolioCard.module.css';

const PortfolioCard = ({ title, description, imageLink, iconLink }) => {
  return (
    <div className={`${styles.card} p-4`}>
      <div className={`${iconLink ? styles.card__wrapper : 'mb-3'}`}>
        {iconLink ? (
          <div className={`${styles.card__icon}`}>{iconLink}</div>
        ) : (
          <img
            src='https://placehold.co/300x250'
            className={`${styles.card__image}`}
            alt='...'
          />
        )}
      </div>

      <div className={`${styles.card__content}`}>
        <h5 className={`h3 ${iconLink ? 'text-center' : 'test-start'}`}>
          {title}
        </h5>
        <p className={`fs-6 ${styles.card__description}`}>{description}</p>
      </div>
    </div>
  );
};

export default PortfolioCard;
