import React from 'react';
import styles from './Footer.module.css';
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from 'react-icons/bs';

const Footer = () => {
  return (
    <footer className={`${styles.footer} `}>
      {/* Body */}
      <div
        className={`d-flex flex-column justify-content-center align-items-center px-4 mx-auto ${styles.footer__content}`}
      >
        {/* logo */}
        <img
          src='https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg'
          alt=''
          width='50'
          className='mb-4'
        />
        <p className='fs-6 mb-4'>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit
          architecto quo officia, nisi consequuntur atque ea deserunt nemo
          obcaecati ad.
        </p>
        <div className='d-flex justify-content-center align-items-center gap-4'>
          <BsFacebook className={`fs-3 ${styles.icon}`} />
          <BsInstagram className={`fs-3 ${styles.icon}`} />
          <BsLinkedin className={`fs-3 ${styles.icon}`} />
          <BsTwitter className={`fs-3 ${styles.icon}`} />
        </div>
      </div>
      {/* Bottom Bar */}
      <div className={`d-flex justify-content-center ${styles.bar}`}>
        <div className='text-center text-lg-start py-4'>
          Copyright &copy; 2024 Prabawa Group | All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
