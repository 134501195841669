import React from 'react';
import styles from './LoadingPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faApple,
  faNeos,
  faProductHunt,
} from '@fortawesome/free-brands-svg-icons';
import { motion, cubicBezier } from 'framer-motion';

const LoadingPage = () => {
  const variants = {
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        delay: 0.5,
        ease: cubicBezier(0.17, 0.55, 0.55, 1),
        staggerChildren: 0.1,
      },
    },
    bottom: { opacity: 0, y: '50px' },
  };

  return (
    <>
      <div className={styles.container}>
        {/* <FontAwesomeIcon icon={faProductHunt} /> */}
        <FontAwesomeIcon icon={faProductHunt} className={styles.logo} />
        <motion.div
          className={styles['logo-title']}
          variants={variants}
          initial='bottom'
          whileInView='animate'
          viewport={{ once: true }}
        >
          prabawa
        </motion.div>
      </div>
    </>
  );
};

export default LoadingPage;
