import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import './App.css';
import * as ROUTES from './Constants/Routes';
import Home from './pages/Home/Home';
import PSI from './pages/PSI/PSI';
import NotFound from './pages/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import Contact from './pages/Contact/Contact';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import PDI from './pages/PDI/PDI';
import Navbar from './components/Navbar/Navbar';
import LoadingPage from './components/Loading/LoadingPage';
import { useEffect, useState } from 'react';

function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // setTimeout(() => setIsLoading(false), 4000);
  }, []);

  return (
    <>
      <AnimatePresence mode='wait'>
        {isLoading ? (
          <motion.div
            key='modal'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: [1, 0, 0],
              transition: {
                duration: 0.7,
                times: [0, 0.7, 1],
                ease: cubicBezier(0.17, 0.55, 0.55, 1),
              },
            }}
            transition={{
              duration: 0.7,
              ease: cubicBezier(0.17, 0.55, 0.55, 1),
            }}
          >
            <LoadingPage />
          </motion.div>
        ) : (
          <>
            <ScrollToTop />
            <Navbar />
            <div className='container-body'>
              <AnimatePresence mode='wait'>
                <Routes location={location} key={location.pathname}>
                  <Route
                    path={ROUTES.HOME}
                    element={<Home isLoading={isLoading} />}
                  />
                  <Route path={ROUTES.PDI} element={<PDI />} />
                  <Route path={ROUTES.PSI} element={<PSI />} />
                  <Route path={ROUTES.CONTACT} element={<Contact />} />
                  <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
                </Routes>
                <Footer />
              </AnimatePresence>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

export default App;
