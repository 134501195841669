import React, { useEffect, useRef, useState } from 'react';
import styles from './PDI.module.css';
import coverImage from '../../img/cover2.jpg';
import Transition from '../../components/Transition/Transition';
import PortfolioCard from '../../components/Card/PortfolioCard';
import CallToAction from '../../components/CTA/CallToAction';
import { cubicBezier, useInView, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const PDI = () => {
  const [t] = useTranslation('global');
  const [isMobile, setIsMobile] = useState(false);
  let animationOffset = isMobile ? '0px' : '-350px';
  let animationOffsetSmall = isMobile ? '0px' : '-250px';

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const variants = {
    left: {
      x: -350,
      opacity: 0,
    },
    right: {
      x: 350,
      opacity: 0,
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: cubicBezier(0.17, 0.55, 0.55, 1),
        staggerChildren: 0.1,
      },
    },
    bottom: { opacity: 0, y: '500px' },
    scale: { opacity: 0, scale: 0.3 },
    scaleActive: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: cubicBezier(0.17, 0.55, 0.55, 1),
      },
    },
  };

  const aboutSectionRef = useRef();
  const isAboutSectionInView = useInView(aboutSectionRef, {
    margin: animationOffset,
  });

  const commitmentSectionRef = useRef();
  const isCommitmentSectionInView = useInView(commitmentSectionRef, {
    margin: animationOffsetSmall,
  });

  const steelSectionRef = useRef();
  const isSteelSectionInView = useInView(steelSectionRef, {
    margin: animationOffset,
  });

  const aluminiumSectionRef = useRef();
  const isAluminiumSectionInView = useInView(aluminiumSectionRef, {
    margin: animationOffset,
  });

  const pdrSectionRef = useRef();
  const isPdrSectionInView = useInView(pdrSectionRef, {
    margin: animationOffset,
  });

  const plasticSectionRef = useRef();
  const isPlasticSectionInView = useInView(plasticSectionRef, {
    margin: animationOffset,
  });

  const ctaSectionRef = useRef();
  const isCtaSectionInView = useInView(ctaSectionRef, {
    margin: animationOffset,
  });

  return (
    <div className={styles.main}>
      {/* Hero section */}
      <section className={styles.section}>
        <h1 className='display-3'>PDI x MWM</h1>
      </section>

      {/* About Section */}
      <section
        className={`row px-4 mx-auto ${styles.container} ${styles['container-about']}`}
        ref={aboutSectionRef}
      >
        <motion.div
          className='col-lg-6 d-flex flex-column justify-content-center align-items-center align-items-lg-start mb-4 mb-lg-0 text-center text-lg-start'
          initial='left'
          animate={isAboutSectionInView && 'animate'}
          variants={variants}
        >
          <motion.p className='fs-5 mb-1 mx-auto mx-lg-0' variants={variants}>
            {t('pdi.about.label')}
          </motion.p>
          <motion.h2
            className='display-5 mb-4 mx-auto mx-lg-0'
            variants={variants}
          >
            {t('pdi.about.title')}
          </motion.h2>
          <motion.p className='lead mb-4' variants={variants}>
            {t('pdi.about.description')}
          </motion.p>
          <motion.a
            href='https://www.mwmautomotive.com/en'
            target='_blank'
            rel='noreferrer nofollow'
            className={`btn rounded-pill shadow ${styles.button}`}
            variants={variants}
          >
            {t('pdi.about.buttonDescription')}
          </motion.a>
        </motion.div>
        <motion.div
          className='col-lg-5 offset-lg-1 d-flex justify-content-center justify-content-lg-end align-items-center'
          initial='scale'
          animate={isAboutSectionInView && 'scaleActive'}
          variants={variants}
        >
          <img
            className={`rounded-3 ${styles.image}`}
            src='https://placehold.co/500x400'
            alt=''
          />
        </motion.div>
      </section>

      {/* Commitment Section */}
      <section
        className={`px-4 ${styles['container-commitment']} ${styles['container-margin-md']}`}
        ref={commitmentSectionRef}
      >
        <div
          className={`container row px-0 mx-auto ${styles.container} margin-bottom-lg padding-y-lg`}
        >
          <motion.div
            className='col-lg-8 offset-lg-2 d-flex flex-column justify-content-center text-center'
            initial='bottom'
            animate={isCommitmentSectionInView && 'animate'}
            variants={variants}
          >
            <motion.h4 className='h1 mb-3' variants={variants}>
              {t('pdi.commitment.title')}
            </motion.h4>
            <motion.p className='lead' variants={variants}>
              {t('pdi.commitment.description')}
            </motion.p>
          </motion.div>
        </div>
      </section>

      {/* Steel Section */}
      <section
        className={`mx-auto px-4 ${styles.container} ${styles['container--steel']}`}
        ref={steelSectionRef}
      >
        <motion.div
          className='mx-auto'
          initial='left'
          animate={isSteelSectionInView && 'animate'}
          variants={variants}
        >
          <motion.p className='fs-5 text-center mb-0' variants={variants}>
            {t('pdi.steel.label')}
          </motion.p>
          <motion.h2 className='display-5 text-center' variants={variants}>
            {t('pdi.steel.title')}
          </motion.h2>
          <motion.p
            className='fs-6 text-center margin-bottom-md'
            variants={variants}
          >
            {t('pdi.steel.description')}
          </motion.p>
        </motion.div>
        <motion.div
          className='row row-cols-1 row-cols-lg-3 my-0 my-sm-3 mx-auto'
          initial='bottom'
          animate={isSteelSectionInView && 'animate'}
          variants={variants}
        >
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('pdi.steel.products.0.title')}
                description={t('pdi.steel.products.0.description')}
                imageLink={coverImage}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('pdi.steel.products.1.title')}
                description={t('pdi.steel.products.1.description')}
                imageLink={coverImage}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('pdi.steel.products.2.title')}
                description={t('pdi.steel.products.2.description')}
                imageLink={coverImage}
              />
            </div>
          </motion.div>
        </motion.div>

        <div className='mx-auto px-4 my-4'>
          <div className='row my-5 pt-0 pt-lg-5 d-flex justify-content-center'>
            <motion.div
              className='mb-4 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-center'
              initial='left'
              animate={isSteelSectionInView && 'animate'}
              variants={variants}
            >
              <motion.h3 className='display-6 mb-3' variants={variants}>
                {t('pdi.steel.titleDemo')}
              </motion.h3>
              <motion.p className='lead' variants={variants}>
                {t('pdi.steel.descriptionDemo')}
              </motion.p>
            </motion.div>
            <motion.div
              className='col-lg-5'
              initial='right'
              animate={isSteelSectionInView && 'animate'}
              variants={variants}
            >
              <motion.div
                className='embed-responsive embed-responsive-16by9'
                variants={variants}
              >
                <iframe
                  height='300'
                  className={`embed-responsive-item w-100 ${styles.container__video}`}
                  src={t('pdi.steel.demoLink')}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Aluminium Section */}
      <section
        className={`container-fluid ${styles['container--dark']}`}
        ref={aluminiumSectionRef}
      >
        <div
          className={`mx-auto px-4 ${styles.container} margin-bottom-lg padding-y-lg`}
        >
          <motion.div
            initial='left'
            animate={isAluminiumSectionInView && 'animate'}
            variants={variants}
          >
            <motion.p className='fs-5 text-center mb-0' variants={variants}>
              {t('pdi.aluminum.label')}
            </motion.p>
            <motion.h2 className='display-5 text-center' variants={variants}>
              {t('pdi.aluminum.title')}
            </motion.h2>
            <motion.p
              className='fs-6 text-center margin-bottom-md'
              variants={variants}
            >
              {t('pdi.aluminum.description')}
            </motion.p>
          </motion.div>
          <motion.div
            className='row row-cols-1 row-cols-lg-3 my-0 my-sm-3 mx-auto'
            initial='bottom'
            animate={isAluminiumSectionInView && 'animate'}
            variants={variants}
          >
            <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
              <div className='d-flex justify-content-center'>
                <PortfolioCard
                  title={t('pdi.aluminum.products.0.title')}
                  description={t('pdi.aluminum.products.0.description')}
                  imageLink={coverImage}
                />
              </div>
            </motion.div>
            <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
              <div className='d-flex justify-content-center'>
                <PortfolioCard
                  title={t('pdi.aluminum.products.1.title')}
                  description={t('pdi.aluminum.products.1.description')}
                  imageLink={coverImage}
                />
              </div>
            </motion.div>
            <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
              <div className='d-flex justify-content-center'>
                <PortfolioCard
                  title={t('pdi.aluminum.products.2.title')}
                  description={t('pdi.aluminum.products.2.description')}
                  variant='light'
                />
              </div>
            </motion.div>
          </motion.div>

          <div className='px-4 my-4'>
            <div className='row my-5 pt-0 pt-lg-5 d-flex justify-content-center'>
              <motion.div
                className='mb-4 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-center'
                initial='left'
                animate={isAluminiumSectionInView && 'animate'}
                variants={variants}
              >
                <motion.h3 className='display-6 mb-3' variants={variants}>
                  {t('pdi.aluminum.titleDemo')}
                </motion.h3>
                <motion.p className='lead' variants={variants}>
                  {t('pdi.aluminum.descriptionDemo')}
                </motion.p>
              </motion.div>
              <motion.div
                className='col-lg-5'
                initial='right'
                animate={isAluminiumSectionInView && 'animate'}
                variants={variants}
              >
                <motion.div
                  className='embed-responsive embed-responsive-16by9'
                  variants={variants}
                >
                  <iframe
                    height='300'
                    className={`embed-responsive-item w-100 ${styles.container__video}`}
                    src={t('pdi.aluminum.demoLink')}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* PDR Section */}
      <section
        className={`mx-auto px-4 ${styles.container} ${styles['container--steel']}`}
        ref={pdrSectionRef}
      >
        <motion.div
          initial='left'
          animate={isPdrSectionInView && 'animate'}
          variants={variants}
        >
          <motion.p className='fs-5 text-center mb-0'>
            {t('pdi.pdr.label')}
          </motion.p>
          <motion.h2 className='display-5 text-center'>
            {t('pdi.pdr.title')}
          </motion.h2>
          <motion.p className='fs-6 text-center margin-bottom-md'>
            {t('pdi.pdr.description')}
          </motion.p>
        </motion.div>
        <motion.div
          className='row row-cols-1 row-cols-lg-3 my-0 my-sm-3 mx-auto'
          initial='bottom'
          animate={isPdrSectionInView && 'animate'}
          variants={variants}
        >
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('pdi.pdr.products.0.title')}
                description={t('pdi.pdr.products.0.description')}
                imageLink={coverImage}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('pdi.pdr.products.1.title')}
                description={t('pdi.pdr.products.1.description')}
                imageLink={coverImage}
              />
            </div>
          </motion.div>
          <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
            <div className='d-flex justify-content-center'>
              <PortfolioCard
                title={t('pdi.pdr.products.2.title')}
                description={t('pdi.pdr.products.2.description')}
                imageLink={coverImage}
              />
            </div>
          </motion.div>
        </motion.div>

        <div className='px-4 my-4'>
          <div className='row my-5 pt-0 pt-lg-5 d-flex justify-content-center'>
            <motion.div
              className='mb-4 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-center'
              initial='left'
              animate={isPdrSectionInView && 'animate'}
              variants={variants}
            >
              <motion.h3 className='display-6 mb-3' variants={variants}>
                {t('pdi.pdr.titleDemo')}
              </motion.h3>
              <motion.p className='lead' variants={variants}>
                {t('pdi.pdr.descriptionDemo')}
              </motion.p>
            </motion.div>
            <motion.div
              className='col-lg-5'
              initial='right'
              animate={isPdrSectionInView && 'animate'}
              variants={variants}
            >
              <motion.div
                className='embed-responsive embed-responsive-16by9'
                variants={variants}
              >
                <iframe
                  height='300'
                  className={`embed-responsive-item w-100 ${styles.container__video}`}
                  src={t('pdi.pdr.demoLink')}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Plastic Section */}
      <section
        className={`container-fluid ${styles['container--dark']}`}
        ref={plasticSectionRef}
      >
        <div
          className={`mx-auto px-4 ${styles.container} margin-bottom-lg padding-y-lg`}
        >
          <motion.div
            initial='left'
            animate={isPlasticSectionInView && 'animate'}
            variants={variants}
          >
            <motion.p className='fs-5 text-center mb-0' variants={variants}>
              {t('pdi.plastic.label')}
            </motion.p>
            <motion.h2 className='display-5 text-center' variants={variants}>
              {t('pdi.plastic.title')}
            </motion.h2>
            <motion.p
              className='fs-6 text-center margin-bottom-md'
              variants={variants}
            >
              {t('pdi.plastic.description')}
            </motion.p>
          </motion.div>
          <motion.div
            className='row row-cols-1 row-cols-lg-3 my-0 my-sm-3 mx-auto'
            initial='bottom'
            animate={isPlasticSectionInView && 'animate'}
            variants={variants}
          >
            <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
              <div className='d-flex justify-content-center'>
                <PortfolioCard
                  title={t('pdi.plastic.products.0.title')}
                  description={t('pdi.plastic.products.0.description')}
                  imageLink={coverImage}
                />
              </div>
            </motion.div>
            <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
              <div className='d-flex justify-content-center'>
                <PortfolioCard
                  title={t('pdi.plastic.products.1.title')}
                  description={t('pdi.plastic.products.1.description')}
                  imageLink={coverImage}
                />
              </div>
            </motion.div>
            <motion.div className='col-lg-4 mb-4 mb-lg-0' variants={variants}>
              <div className='d-flex justify-content-center'>
                <PortfolioCard
                  title={t('pdi.plastic.products.2.title')}
                  description={t('pdi.plastic.products.2.description')}
                  variant='light'
                />
              </div>
            </motion.div>
          </motion.div>

          <div className='px-4 my-4'>
            <div className='row my-5 pt-0 pt-lg-5 d-flex justify-content-center'>
              <motion.div
                className='mb-4 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-center'
                initial='left'
                animate={isPlasticSectionInView && 'animate'}
                variants={variants}
              >
                <motion.h3 className='display-6 mb-3' variants={variants}>
                  {t('pdi.plastic.titleDemo')}
                </motion.h3>
                <motion.p className='lead' variants={variants}>
                  {t('pdi.plastic.descriptionDemo')}
                </motion.p>
              </motion.div>
              <motion.div
                className='col-lg-5'
                initial='right'
                animate={isPlasticSectionInView && 'animate'}
                variants={variants}
              >
                <motion.div
                  className='embed-responsive embed-responsive-16by9'
                  variants={variants}
                >
                  <iframe
                    height='300'
                    className={`embed-responsive-item w-100 ${styles.container__video}`}
                    src={t('pdi.plastic.demoLink')}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen
                  ></iframe>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* Call To Action Section*/}
      <section
        className='container-fluid px-0 px-sm-3 mt-5'
        ref={ctaSectionRef}
      >
        <CallToAction
          title={t('pdi.callToAction.title')}
          description={t('pdi.callToAction.description')}
          isInView={isCtaSectionInView}
        />
      </section>
    </div>
  );
};

export default Transition(PDI);
